/* global $ */
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $(".sub-dropdown").slideUp();

    $(".nav-column li > .menu").on("click", function handler(e) {
      e.stopPropagation();
      e.preventDefault();
      $(this).next(".sub-dropdown").slideToggle("slow");
    });
  }
}
