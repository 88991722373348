/* global $ */
/* global gon */
/* global google */
import { Controller } from "stimulus";
import verge from "verge";

import loadScript from "~/lib/asyncScriptLoader";
import { placesUri } from "~/lib/GoogleGeocomplete";
import mapIcon from "../images/map-icon.png";

export default class extends Controller {
  static targets = ["staticMap", "officesMap"];

  connect() {
    loadScript(placesUri).then(() => {
      this.createOfficesMap();
      this.createStaticMap();
      this.createDirectionsMap();
    });
  }

  /*
   * Create Map for the list of offices
   */
  createOfficesMap() {
    if (this.hasOfficesMapTarget) {
      const { markers } = gon.offices;

      const map = this.initOfficesMap(this.officesMapTarget, { markers });

      google.maps.event.addDomListener(window, "resize", () => {
        const center = this.centreForCurrentWidth();
        map.setCenter(center);
      });
    }
  }

  /*
   * Create Map for a given position
   */
  createStaticMap() {
    if (this.hasStaticMapTarget) {
      this.initMapWithMarker(
        this.staticMapTarget,
        this.staticMapTarget.dataset,
      );
    }
  }

  /*
   * Create Map for the directions modal
   */
  createDirectionsMap() {
    const directionsMapCanvas = document.getElementById(
      "directions-map-canvas",
    );

    $("#directions-modal").on("show.bs.modal", ({ relatedTarget }) => {
      $("#directions-modal")
        .find("h4.office-name")
        .html(relatedTarget.dataset.name);

      this.initMapWithMarker(directionsMapCanvas, relatedTarget.dataset);
    });

    $("#directions-modal").on("hide.bs.modal", (e) => {
      e.currentTarget
        .querySelector(".small-section")
        .classList.replace("d-block", "d-none");
    });
  }

  /*
   * centreForCurrentWidth
   *
   * returns a LatLng object based on the width of the viewport
   */
  centreForCurrentWidth() {
    const currentViewportW = verge.viewportW();

    if (currentViewportW < 768)
      return new google.maps.LatLng(
        "-27.781435827932583",
        "139.75391056614788",
      );
    if (currentViewportW < 992)
      return new google.maps.LatLng("-28.953328298111824", "135.7773343892736");
    return new google.maps.LatLng("-30.990331611868434", "115.25127674999999");
  }

  /*
   * initMap
   *
   * initialise a google map on a given element
   */
  initMap({ elem, zoom = 3 }) {
    const bounds = new google.maps.LatLngBounds();
    const center = this.centreForCurrentWidth();

    const mapOptions = {
      center,
      zoom,
      disableDefaultUI: true,
    };

    const map = new google.maps.Map(elem, mapOptions);
    this.assignStyledMap({ map });
    // this.setupDebugging({ map });

    return { map, center, bounds };
  }

  initOfficesMap(elem, { markers }) {
    const { map, center, bounds } = this.initMap({ elem, zoom: 3 });

    markers.map((data) => this.addMarker({ elem, map, bounds, data }));

    google.maps.event.trigger(map, "resize");

    // Reset the center
    map.setCenter(center);

    return map;
  }

  initMapWithMarker(elem, dataset) {
    const { map, bounds } = this.initMap({ elem, zoom: 15 });

    this.addMarker({ elem, map, bounds, data: dataset });

    google.maps.event.trigger(map, "resize");

    return map;
  }

  /*
   * addMarker
   *
   * add a marker to a map, and expand the bounds of the map
   */
  addMarker({ elem, map, bounds, data }) {
    const { latitude, longitude, name, address, phone } = data;
    const position = new google.maps.LatLng(latitude, longitude);

    const marker = new google.maps.Marker({
      position,
      map,
      name,
      address,
      phone,
      icon: mapIcon,
    });

    this.bindMarkerInfoWindow(elem, marker);

    marker.setMap(map);
    bounds.extend(position);
    map.setCenter(position);
  }

  bindMarkerInfoWindow(elem, marker) {
    marker.addListener("click", function showInfoWindow() {
      const { name, address, phone } = this;

      const infoWindow = elem.parentNode.querySelector(".small-section");

      infoWindow.querySelector(".office-title").innerHTML = name;
      infoWindow.querySelector(".office-address").innerHTML = address;
      infoWindow.querySelector(".office-phone").innerHTML = phone;

      if (infoWindow.classList.contains("d-none")) {
        infoWindow.classList.replace("d-none", "d-block");
      }
    });
  }

  assignStyledMap({ map }) {
    const styles = [
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }, { lightness: 17 }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }, { lightness: 18 }],
      },
      {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }, { lightness: 16 }],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#dedede" }, { lightness: 21 }],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          { visibility: "on" },
          { color: "#ffffff" },
          { lightness: 16 },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [{ saturation: 36 }, { color: "#333333" }, { lightness: 40 }],
      },
      {
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
      },
      {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [{ color: "#fefefe" }, { lightness: 20 }],
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
      },
    ];

    const styledMap = new google.maps.StyledMapType(styles, {
      name: "Styled Map",
    });
    map.mapTypes.set("map_style", styledMap);
    map.setMapTypeId("map_style");
  }

  // setupDebugging({ map }) {
  //   map.addListener("bounds_changed", () => {
  //     console.log("bounds: ", map.getBounds());
  //   });
  //   map.addListener("center_changed", () => {
  //     console.log("center now: ", map.center.lat(), map.center.lng());
  //   });
  //   map.addListener("zoom_changed", () => {
  //     console.log("zoom level: ", map.getZoom());
  //   });
  // }
}
