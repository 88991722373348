/* global $ */
import { Controller } from "stimulus";

import "@selectize/selectize";

export default class extends Controller {
  static targets = ["input", "inputWithOnChange", "onChangeElement"];

  connect() {
    Array.from([...this.inputTargets]).forEach((input) =>
      $(input).selectize({
        delimiter: ", ",
        create: (text) => ({
          value: text,
          text,
        }),
      }),
    );
    Array.from([...this.inputWithOnChangeTargets]).forEach((input) =>
      $(input).selectize({
        delimiter: ", ",
        create: (text) => ({
          value: text,
          text,
        }),
        onChange: this.onChange,
      }),
    );
  }

  onChange = () => {
    this.onChangeElementTarget.click();
  };
}
