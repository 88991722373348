/* global $ */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleElem", "toggleTarget"];

  connect() {
    // calling the jQuery plugin DOES have a side-effect
    // eslint-disable-next-line no-unused-expressions
    [...this.toggleTargetTargets.map((input) => $(input).slideUp())];
  }

  toggle(e) {
    $(e.currentTarget).next(".address").slideToggle("slow");
  }
}
