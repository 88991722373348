/* global $ */
export default ({
  selector,
  confirmDismiss,
  confirmCallback,
  content,
  title,
}) => {
  const $confirmModal = $(selector);

  $confirmModal.find(".modal-title").html(title);
  $confirmModal.find(".modal-body").html(content);

  $confirmModal.modal("show");

  $("button[data-dismiss='ok']", $confirmModal).on("click", async () => {
    if (confirmCallback) {
      await confirmCallback();
      if (confirmDismiss) {
        $confirmModal.modal("hide");
      }
    }
  });

  $confirmModal.on("hide.bs.modal", () => {
    $("button[data-dismiss='ok']", $confirmModal).off("click");
  });

  return $confirmModal;
};
