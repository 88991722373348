/* global $ */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menuToggle"];

  toggleMenu({ currentTarget }) {
    if (currentTarget.classList.contains("is-active")) {
      currentTarget.classList.remove("is-active");
    } else {
      currentTarget.classList.add("is-active");
    }

    const mobileMenu = currentTarget
      .closest(".mobile-header")
      .querySelector(".mobile-nav-menu");
    if (mobileMenu.classList.contains("open")) {
      mobileMenu.classList.remove("open");
    } else {
      mobileMenu.classList.add("open");
    }

    if (document.body.classList.contains("drawer-open")) {
      document.body.classList.remove("drawer-open");
    } else {
      document.body.classList.add("drawer-open");
    }
  }

  toggleVisibility(elem) {
    if (elem.classList.contains("hidden")) {
      elem.classList.remove("hidden");
    } else {
      elem.classList.add("hidden");
    }
  }

  connect() {
    const toggles = document.getElementsByClassName("menu-toggle");

    Array.from(toggles).forEach((toggleElem) => {
      toggleElem.addEventListener("click", () => {
        const menu = toggleElem.closest(".submenu");
        if (menu.classList.contains("open")) {
          menu.classList.remove("open");
        } else {
          menu.classList.add("open");
        }
        if (toggleElem.classList.contains("open")) {
          toggleElem.classList.remove("open");
        } else {
          toggleElem.classList.add("open");
        }
      });
    });

    $(".dropdown-submenu a.toggle").on("click", function slideMenu(e) {
      e.stopPropagation();
      e.preventDefault();

      $(this).next("ul").slideToggle("slow");
    });

    $(".toggle-plus").click(function expandMenu() {
      $(this).find("i").toggleClass("fa-plus fa-minus");
      if ($(".dropdown-toggle").not(this).find("i").hasClass("fa-plus")) {
        $(".dropdown-toggle")
          .not(this)
          .find("i")
          .toggleClass("fa-plus fa-minus");
      }
    });
  }
}
