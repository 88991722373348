/* global $ */
import "bootstrap-notify";

const flash = (options, settings) => {
  $.notify(options, settings);
};

const defaultFlashOptions = () => ({});

const defaultFlashSettings = () => ({
  type: "info",
  allow_dismiss: false,
  placement: {
    from: "top",
    align: "center",
  },
  offset: 72,
  delay: 5000,
  timer: 500,
});

const customFlashOptions = (options = {}) => ({
  ...defaultFlashOptions(),
  ...options,
});

const customFlashSettings = (settings = {}) => {
  // If the type of flash alert is "alert" - a common rails thing, we map this to "danger" for bootstrap
  Object.assign(
    settings,
    Object.keys(settings)
      .filter((key) => key === "type" && settings[key] === "alert")
      .map((key) => [key, "danger"])
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {}),
  );
  return { ...defaultFlashSettings(), ...settings };
};

const FlashMessages = (customFlash) => {
  if ($.isEmptyObject(customFlash)) {
    // Default Rails Flash Messages
    const viewFlash = $("#flash");

    if (viewFlash.length > 0) {
      const defaultOptions = customFlashOptions({
        message: viewFlash.data("message"),
      });
      const defaultSettings = customFlashSettings({
        type: viewFlash.data("type"),
      });

      flash(defaultOptions, defaultSettings);
    }
  } else {
    // Custom flash messages (AJAX responses/manual/etc)
    // NB at this time customFlash must be an object with options and settings objects
    flash(
      customFlashOptions(customFlash.options),
      customFlashSettings(customFlash.settings),
    );
  }
};

export default FlashMessages;
