/* global gon */
/* global Stripe */
import { Controller } from "stimulus";

import loadScript from "~/lib/asyncScriptLoader";

const stripeJSUri = "https://js.stripe.com/v3/";

export default class extends Controller {
  static targets = [
    "cardElement",
    "cardErrors",
    "form",
    "cardToken",
    "cardType",
    "cardLast4",
  ];

  connect() {
    loadScript(stripeJSUri).then(() => {
      this.initializeStripe();
    });
  }

  initializeStripe() {
    this.stripe = Stripe(gon.stripe.publishableKey);
    const elements = this.stripe.elements();

    const classes = {
      base: "form-control stripe-card",
    };

    const style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    this.card = elements.create("card", {
      classes,
      style,
    });
    this.card.mount(this.cardElementTarget);
    console.log("initializeStripe - this", this);
    console.log("initializeStripe - this.card", this.card);

    this.card.addEventListener("change", (event) => {
      const displayError = this.cardErrorsTarget;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });

    // Handle form submission.
    const form = this.formTarget;
    form.addEventListener("submit", this.handleStripeSubmit);
  }

  handleStripeSubmit = (event) => {
    console.log("handleStripeSubmit - this", this);
    event.preventDefault();
    this.stripe.createToken(this.card).then((result) => {
      console.log("Stripe Result", result);
      if (result.error) {
        // Inform the user if there was an error.
        const errorElement = this.cardErrorsTarget;
        errorElement.textContent = result.error.message;
      } else {
        // Send the token to your server.
        this.stripeTokenHandler(result.token);
      }
    });
  };

  // Submit the form with the token ID.
  stripeTokenHandler({ id, card }) {
    // Insert the token ID into the form so it gets submitted to the server
    const form = this.formTarget;
    // const tokenInput = document.createElement("input")
    // tokenInput.setAttribute("type", "hidden")
    // tokenInput.setAttribute("name", "training_booking_form[stripe_card_token]")
    // tokenInput.setAttribute("value", token.id)
    // form.appendChild(tokenInput)

    this.cardTokenTarget.value = id;
    this.cardTypeTarget.value = card.brand;
    this.cardLast4Target.value = card.last4;

    // Submit the form
    form.submit();
  }

  destroyStripe() {
    this.formTarget.removeEventListener("submit", this.handleStripeSubmit);
    if (this.card) {
      console.log("destroyStripe - this.card", this.card);
      this.card.destroy();
    }
  }

  hideCardErrors() {
    this.cardErrorsTarget.classList.add("d-none");
  }

  showCardErrors() {
    this.cardErrorsTarget.classList.remove("d-none");
  }
}
