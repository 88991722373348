/* global $ */
import { Controller } from "stimulus";

import "~/vendor/dnCalendar/js/dncalendar";
import "~/vendor/dnCalendar/css/dncalendar-skin.css";

export default class extends Controller {
  static targets = ["calendar", "event", "loadingIndicator"];

  isLoading = false;

  connect() {
    this.loadCalendar();

    if (this.hasLoadingIndicatorTarget) {
      this.checkForLoadedContent();
    }
  }

  loadCalendar() {
    if (this.hasCalendarTarget) {
      this.calendar = $(this.calendarTarget).dnCalendar({
        notes: this.getEnabledDates(),
      });
      this.calendar.build();

      if (this.hasLoadingIndicatorTarget) {
        this.loadingIndicatorTarget.style.display = "none";
      }
    }
  }

  checkForLoadedContent() {
    this.refreshTimer = setInterval(() => {
      if ($(this.loadingIndicatorTarget).is(":visible")) {
        this.calendar.update({ notes: this.getEnabledDates() });
        this.loadingIndicatorTarget.style.display = "none";
      }
    }, 250);
  }

  getEnabledDates() {
    const eventDates = Array.from(this.eventTargets, (event) => ({
      date: event.dataset.date,
    }));
    return [...new Set(eventDates)];
  }
}
