/* global $ */
import { TempusDominus } from "@eonasdan/tempus-dominus";
import { Controller } from "stimulus";

import "@eonasdan/tempus-dominus/dist/css/tempus-dominus.min.css";

export default class extends Controller {
  static targets = ["courseForm", "event", "newFieldsNode"];

  connect() {
    this.bindNestedFields(this.courseFormTarget);

    Array.from(this.eventTargets).forEach((elem) => {
      this.bindDatePickerInput(elem);
      this.bindExternalRegistrationFields(elem);
    });

    this.resetEventNumbers();
  }

  bindNestedFields(node) {
    $(node).on("cocoon:before-insert", (event, insertedItem) => {
      if (event.target.id === this.newFieldsNodeTarget.id) {
        this.bindDatePickerInput(insertedItem.get(0));
        this.bindExternalRegistrationFields(insertedItem.get(0));
      }
    });

    $(node).on("cocoon:after-insert", (event) => {
      if (event.target.id === this.newFieldsNodeTarget.id) {
        this.resetEventNumbers();
      }
    });

    $(node).on("cocoon:after-remove", (event) => {
      if (event.target.id === this.newFieldsNodeTarget.id) {
        this.resetEventNumbers();
      }
    });
  }

  bindDatePickerInput(elem) {
    const datePickers = elem.querySelectorAll(".datePickerInput");
    Array.from(datePickers).forEach(
      (input) =>
        new TempusDominus(input, {
          localization: {
            format: "dd/MM/yyyy hh:mm T",
          },
          stepping: 15,
        }),
    );
  }

  bindExternalRegistrationFields(elem) {
    const externalEvent = elem.querySelectorAll(".external-event");
    const externalLink = elem.querySelectorAll(".external-link");
    $(externalLink).prop("disabled", !$(externalEvent).is(":checked"));
    $(externalEvent).on("change", ({ target }) => {
      $(externalLink).prop("disabled", !target.checked);
    });
  }

  resetEventNumbers() {
    const nodes = this.courseFormTarget.querySelectorAll(".event");
    Array.from(nodes).forEach((node, index) => {
      node.querySelector(".event-number").innerHTML = `Event ${index + 1}`;
    });
  }

  toggleAddress(target) {
    const addressTarget = target.closest(".event").find(".address-components");
    addressTarget.toggleClass("d-block", !target.is(":checked"));
    addressTarget.toggleClass("d-none", target.is(":checked"));
  }
}
