import axios from "axios";
import humps from "humps";
import { normalize } from "normalizr";

export const defaultJSONHeaders = {
  Accept: "application/json",
  "Content-Type": "application/x-www-form-urlencoded",
};

export const convertRequest = (data) => humps.decamelizeKeys(data);
export const convertResponse = (data) => humps.camelizeKeys(data);

// Normalize a certain set of data
export const normalizeData = (data, schema) =>
  normalize(convertResponse(data), schema);
// Generally normalize a response
export const normalizeResponse = (response, schema) => ({
  response: normalizeData(response.data, schema),
});

// Some default response functions
export const handleResponse = (response) => ({
  response: convertResponse(response.data),
});
export const handleError = (error) => ({
  errors: convertResponse(error.response.data.errors),
});

export const makeRequest = (config) => axios(config);
export const makeDELETERequest = (uri, config = {}) =>
  axios.delete(uri, config);
export const makeGETRequest = (uri, config = {}) => axios.get(uri, config);
export const makePATCHRequest = (uri, body, config = {}) =>
  axios.patch(uri, convertRequest(body), config);
export const makePOSTRequest = (uri, body, config = {}) =>
  axios.post(uri, convertRequest(body), config);

// The most generic API request
// - mostly used in redux sagas as the response and error objects are a specific structure
export const processRequest = (apiFunc) =>
  apiFunc.then(handleResponse).catch(handleError);
