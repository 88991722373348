/* global $ */
import { Controller } from "stimulus";

// We know these values will be whole dollars amounts
function formatCurrency(amount) {
  return parseFloat(amount).toLocaleString("en-AU", {
    style: "currency",
    currency: "AUD",
  });
}

export default class extends Controller {
  static targets = [
    "discountCode",
    "courseCost",
    "newFieldsNode",
    "registrationForm",
    "registrant",
    "totalPrice",
    "paymentForm",
    "affiliatedAssociation",
    "affiliatedAssociationMemberNumber",
    "affiliatedFranchise",
    "affiliatedFranchiseLocation",
  ];

  connect() {
    this.bindNestedFields(this.registrationFormTarget);

    Array.from(this.registrantTargets).forEach((elem, index) => {
      // First registrant cannot be removed
      if (index !== 0) {
        this.showDivider($(elem));
        this.showRemoveButton($(elem));
      }
    });

    this.calculateTotalPrice();
    this.resetRegistrantNumbers();
    this.maybeApplyDiscount();
  }

  bindNestedFields(node) {
    $(node).on("cocoon:before-insert", (_, insertedItem) => {
      this.showDivider(insertedItem);
      this.showRemoveButton(insertedItem);
    });

    $(node).on("cocoon:before-remove", () => {});

    $(node).on("cocoon:after-insert", () => {
      this.calculateTotalPrice();
      this.resetRegistrantNumbers();
    });

    $(node).on("cocoon:after-remove", () => {
      this.calculateTotalPrice();
      this.resetRegistrantNumbers();
    });
  }

  calculateTotalPrice() {
    const registrantCount = this.numberOfRegistrants();
    const totalPrice =
      parseFloat(this.courseCostTarget.dataset.value) * registrantCount;

    this.totalPriceTargets.forEach((elem) => {
      elem.innerHTML = `Total Price incl GST ${formatCurrency(
        totalPrice.toFixed(2).toString(),
      )} (${registrantCount} ${registrantCount === 1 ? "person" : "people"})`;
    });
  }

  getRegistrantNodes() {
    return this.registrationFormTarget.querySelectorAll(".registrant");
  }

  maybeApplyDiscount() {
    if (
      this.affiliatedAssociationTarget.value &&
      this.affiliatedAssociationMemberNumberTarget.value
    ) {
      this.applyFullDiscount();
    } else if (
      this.affiliatedFranchiseTarget.value &&
      this.affiliatedFranchiseLocationTarget.value
    ) {
      this.applyFullDiscount();
    } else {
      this.removeDiscount();
    }
  }

  numberOfRegistrants() {
    return this.getRegistrantNodes().length;
  }

  resetRegistrantNumbers() {
    const nodes = this.getRegistrantNodes();
    Array.from(nodes).forEach((node, index) => {
      node.querySelector(".registrant-id").innerHTML = `Attendee ${index + 1}`;
    });
  }

  showDivider(elem) {
    elem.find("hr").removeClass("d-none");
  }

  showRemoveButton(elem) {
    elem.find(".remove-registrant").removeClass("invisible");
  }

  stripeController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("registration-form"),
      "stripe",
    );
  }

  applyDiscountCode(e) {
    e.preventDefault();
    e.stopPropagation();
    // Refresh with discount in URL params
    const discountCode = this.discountCodeTarget.value;
    const urlWithDiscountParam = new URL(window.location.href);
    urlWithDiscountParam.searchParams.set("discount", discountCode);
    window.location.href = urlWithDiscountParam.href;
  }

  applyFullDiscount() {
    this.courseCostTarget.dataset.value = 0;
    this.calculateTotalPrice();
    this.stripeController().destroyStripe();
    this.hidePaymentForm();
    this.hideCardErrors();
  }

  removeDiscount() {
    this.courseCostTarget.dataset.value =
      this.courseCostTarget.dataset.originalValue;
    this.calculateTotalPrice();
    this.stripeController().initializeStripe();
    this.showPaymentForm();
    this.showCardErrors();
  }

  hideCardErrors() {
    this.stripeController().hideCardErrors();
  }

  showCardErrors() {
    this.stripeController().showCardErrors();
  }

  hidePaymentForm() {
    this.paymentFormTarget.classList.add("d-none");
  }

  showPaymentForm() {
    this.paymentFormTarget.classList.remove("d-none");
  }
}
